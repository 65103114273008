import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import roles from './roles/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    staff(),
    roles(),
  ])
}
