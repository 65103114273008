import { createSelector } from 'reselect'

export const selectUser = state => state.user

export const selectSuperAdminData = createSelector([selectUser], user => {
  const userInfo = user?.userRole
  if (!userInfo) return false
  return userInfo === 'Super Admin' || userInfo === 'Vice President' || userInfo === 'Director'
})

// || userInfo === 'Director' || userInfo === 'Branch Manager' ||

export const selectDirectorData = createSelector([selectUser], user => {
  const userInfo = user?.userRole
  if (!userInfo) return false
  return userInfo === 'Director'
})

export const selectBranchManagerData = createSelector([selectUser], user => {
  const userInfo = user?.userRole
  if (!userInfo) return false
  return userInfo === 'Branch Manager'
})

export const selectSalesData = createSelector([selectUser], user => {
  const userInfo = user?.userRole
  if (!userInfo) return false
  return userInfo === 'Sales Manager'
})
