const actions = {
  SET_STATE: 'roles/SET_STATE',
  REMOVE_DATA: 'roles/REMOVE_DATA',
  SHOW_CREATE: 'roles/SHOW_CREATE',
  HIDE_CREATE: 'roles/HIDE_CREATE',
  SHOW_UPDATE: 'roles/SHOW_UPDATE',
  HIDE_UPDATE: 'roles/HIDE_UPDATE',
  SHOW_DELETE: 'roles/SHOW_DELETE',
  HIDE_DELETE: 'roles/HIDE_DELETE',

  LOAD_ALL: 'roles/LOAD_ALL',
  CREATE: 'roles/CREATE',
  UPDATE: 'roles/UPDATE',
  REMOVE: 'roles/REMOVE',
}

export default actions
