import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import getMenuData from 'services/menu'
import actions from './actions'
import {
  selectSuperAdminData,
  // selectDirectorData,
  // selectBranchManagerData,
  // selectSalesData
} from '../user/selectors'

export function* SET_DATA() {
  const menuData = yield call(getMenuData)
  const canAccessSuperAdmin = yield select(selectSuperAdminData)
  // const canAccessDirector = yield select(selectDirectorData)
  // const canAccessBranchManager = yield select(selectBranchManagerData)
  // const canAccessSalesManager = yield select(selectSalesData)
  if (!canAccessSuperAdmin) menuData.splice(menuData.map(o => o.key).indexOf('manage-organisation'), 1)
  if (!canAccessSuperAdmin) menuData.splice(menuData.map(o => o.key).indexOf('manage-bulk-sms'), 1)
  if (!canAccessSuperAdmin) menuData.splice(menuData.map(o => o.key).indexOf('manage-dashboard'), 1)
  // if (!canAccessDirector) menuData.splice(menuData.map(o => o.key).indexOf('manage-organisation'), 1)
  // if (!canAccessBranchManager) menuData.splice(menuData.map(o => o.key).indexOf('manage-organisation'), 1)
  // if (!canAccessSalesManager) menuData.splice(menuData.map(o => o.key).indexOf('manage-organisation'), 1)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SET_DATA, SET_DATA),
    SET_DATA(), // run once on app load to fetch menu data
  ])
}


// export default function* rootSaga() {
//   yield all([
//     takeLatest(actions.GET_DATA, GET_DATA),
//     GET_DATA(), // run once on app load to fetch menu data
//     yield all([takeLatest(actions.SET_DATA, SET_DATA), SET_DATA()])
//   ])
// }


// export default function* rootSaga() {
//   yield all([takeLatest(actions.SET_DATA, SET_DATA), SET_DATA()])
// }
