import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { create, loadAllUsers, filterUser, loadAll, remove, sendsms, update, sendgreetings, sendbulksms, loadtree, downloadpdf } from 'api/staff'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}


export function* DOWNLOAD_PDF({ payload }) {
  const { response } = yield call(downloadpdf, payload)
  if (response) {
    const { pdfUrl } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        pdfData: pdfUrl,
      },
    })
  }
}




export function* LOAD_ORGANISATION_TREE() {
  const { response } = yield call(loadtree)
  if (response) {
    const { GetVicePresident, GetDirector, GetBranchManager, GetSalesManager } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        VicePresident: GetVicePresident,
        Director: GetDirector,
        BranchManager: GetBranchManager,
        SalesManager: GetSalesManager,
      },
    })
  }
}




export function* LOAD_ALL_USERS() {
  const { response } = yield call(loadAllUsers)
  if (response) {
    const { allUserlist } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        userListInfodata: allUserlist,
      },
    })
  }
}

export function* GETFILTER({ payload }) {
  const { response } = yield call(filterUser, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        getFilteredUser: null,
        getAllUsersInfo: null,
        currentRoleFilter: null,
      },
    })
    const { getFiltered, getAllUsers, filterName } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        getFilteredUser: getFiltered,
        getAllUsersInfo: getAllUsers,
        currentRoleFilter: filterName,
      },
    })
  }
}

export function* SEND_SMS({ payload }) {
  const { response } = yield call(sendsms, payload?.staffId)
  if (response) {
    const { getFiltered } = response.data
    yield put({
      type: actions.HIDE_SMS_POPUP,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        getFilteredUser: getFiltered,
      },
    })
  }
}

export function* SEND_BIRTHDAY_GREETINGS({ payload }) {
  const { response } = yield call(sendgreetings, payload)
  if (response) {
    yield put({
      type: actions.HIDE_BIRTHDAY_POPUP,
    })
  }
}

export function* SEND_BULK_SMS({ payload }) {
  const { response } = yield call(sendbulksms, payload)
  if (response) {
    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     messageList: response?.data?.messageList,
    //   },
    // })
  }
}

export function* CREATE({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { UseridSel, updatedData } = payload
  const { response } = yield call(update, UseridSel, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    const { userlist } = response.data
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        userInfodata: userlist,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.SEND_SMS, SEND_SMS),
    takeLatest(actions.SEND_BIRTHDAY_GREETINGS, SEND_BIRTHDAY_GREETINGS),
    takeLatest(actions.LOAD_ALL_USERS, LOAD_ALL_USERS),
    takeLatest(actions.GETFILTER, GETFILTER),
    takeLatest(actions.SEND_BULK_SMS, SEND_BULK_SMS),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.LOAD_ORGANISATION_TREE, LOAD_ORGANISATION_TREE),
    takeEvery(actions.DOWNLOAD_PDF, DOWNLOAD_PDF),
  ])
}
