import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import staff from './staff/reducers'
import roles from './roles/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    staff,
    roles,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
