import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { create, loadAll, remove } from 'api/roles'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    console.log("sagas 08 load all", response)
    const { roleList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        roleInfoData: roleList,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { shouldAdd, ...formValues } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(create, formValues)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { roleList } = response.data
    console.log("sagas", response)
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        roleInfoData: roleList,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    const { roleList } = response.data
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        roleInfoData: roleList,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.REMOVE, REMOVE),
  ])
}
