import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}/getroles`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/createRole`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(staffId) {
  return apiClient
    .put(`${BASE_URL}/deleteroles/${staffId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
